import React, { useEffect, useState } from "react";
import ProductItem from "./ProductItem";
import Slider from "react-slick";
import MoreProducts from '../../main/ReadMore'
import { Link } from "react-router-dom";
import { isIterableArray } from "../utils";

const ArrowPrev = ({ onClick }) => (
  <div className="slick-custom-btn slick-custom-btn__prev" onClick={onClick}>
    <i className="icon-arrow-left2"></i>
  </div>
);
const ArrowNext = ({ onClick }) => (
  <div className="slick-custom-btn slick-custom-btn__next" onClick={onClick}>
    <i className="icon-arrow-right2"></i>
  </div>
);

const ProductSlider = ({ products, linkViewAll, animation_product, sliderConfig }) => {
  const sliderConfiguration = {
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    initialSlide: 0,
    nextArrow: <ArrowNext />,
    prevArrow: <ArrowPrev />,
    // responsive:  [
    //   {
    //     breakpoint: 1260,
    //     settings: {
    //       slidesToShow: 5,
    //       slidesToScroll: 5,
    //     },
    //   },
    //   {
    //     breakpoint: 1024,
    //     settings: {
    //       slidesToShow: 3,
    //       slidesToScroll: 3,
    //     },
    //   },
    //   {
    //     breakpoint: 800,
    //     settings: {
    //       slidesToShow: 2,
    //       slidesToScroll: 2,
    //     },
    //   },
    //   {
    //     breakpoint: 480,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //     },
    //   },
    // ],
    ...sliderConfig
  };

  const isProductsHasVariable = isIterableArray(products.filter(item => (item.color !== null)))


  const getLink = (linkObj) => {
    if (typeof linkObj === 'string') {
      if (linkObj.indexOf("https://galaktika.me") !== -1)
        return linkObj.replace("https://galaktika.me/", '/')
      if (linkObj.indexOf("https://avoidrandomusers99.galaktika.me/") !== -1)
        return linkObj.replace("https://avoidrandomusers99.galaktika.me/", '/')
      return '/' + linkObj
    }

    if (linkObj.type === 'link') {
      return linkObj.url
      // if (linkObj.url.indexOf("https://galaktika.me") !== -1)
      //   return linkObj.url.replace("https://galaktika.me/", '/')
      // if (linkObj.url.indexOf("https://avoidrandomusers99.galaktika.me/") !== -1)
      //   return linkObj.url.replace("https://avoidrandomusers99.galaktika.me/", '/')
      // return '/' + linkObj.url
    }

    if (!linkObj || (linkObj.type !== 'prod' && linkObj.type !== 'cat')) return '/'

    if (linkObj.type === 'prod') return '/product/' + linkObj.name_slug
    if (linkObj.type === 'cat' && linkObj.type_cat === 'have_cat') return '/category/' + linkObj.name_slug
    if (linkObj.type === 'cat' && linkObj.type_cat === 'no_have_cat') return '/catalog/' + linkObj.name_slug
  }


  return (
    <div className="product-slider-mobile">
      <div className={`d-sm-none`}> {/* ${isShowMore ? '' : 'show-all'} */}

        <MoreProducts

          initialHeight={isProductsHasVariable ? 360 : 320}
          // initialHeight={isProductsHasVariable ? 382 : 338}
          overhangSize={110}
          readMore={props => (
            <div className={`products-dropdown__btn ${props.open && 'd-none'}`}>
              <div className={props.open ? 'open' : ''}
                onClick={props.onClick}>{props.open ? 'Свернуть' : 'Показать больше'}<i className="i-arrow"></i>
              </div>
            </div>
          )}
        >
          <div className="mobile-products">
            {products.map((product, index) => (
              <ProductItem key={index} product={product} animation_product={animation_product} isProductsHasVariable={isProductsHasVariable} />
            ))}
          </div>
          {/* {console.log('linkViewAll linkViewAll linkViewAll', linkViewAll)} */}
          {linkViewAll &&
            <Link
              rel={linkViewAll.nofollow ? 'nofollow' : ''}
              className="products-dropdown__btn d-block to-category"
              to={{ pathname: getLink(linkViewAll) }}
              target={linkViewAll.type === 'link' ? "_blank" : '_self'}
            >
              <div className="">
                Посмотреть все товары
                <span style={{ display: 'flex' }}>
                  <i className="i-arrow next"
                    style={{
                      display: 'inline-block',
                      transform: 'rotate(-90deg) translateY(-5px)'
                    }}
                  />
                  <i className="i-arrow next"
                    style={{
                      display: 'inline-block',
                      transform: 'rotate(-90deg) translateY(-26px)'
                    }}
                  />
                </span>
              </div>
            </Link>
          }
        </MoreProducts>
        {/* {
          isShowMore &&
          <div className="products-dropdown__btn mb-2 mx-auto" onClick={() => setIsShowMore(false)}>
            <div className="bg-white">
              Показать больше <i className="i-arrow"></i>
            </div>
          </div>
        } */}
      </div>
      <div className="d-none d-sm-block" >
        <Slider {...sliderConfiguration}>
          {products.map((product, index) => (
            <ProductItem key={index} product={product} animation_product={animation_product} isProductsHasVariable={isProductsHasVariable} />
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default ProductSlider;
